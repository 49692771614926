import map1 from 'assets/gallery/1.png';
import map2 from 'assets/gallery/2.png';
import map3 from 'assets/gallery/3.png';
import map4 from 'assets/gallery/4.png';
import map5 from 'assets/gallery/5.png';
import map6 from 'assets/gallery/6.png';
import map7 from 'assets/gallery/7.png';
import map8 from 'assets/gallery/8.png';
import map9 from 'assets/gallery/9.png';

export const GALLERY_ITEMS = [map1, map2, map3, map4, map5, map6, map7, map8, map9];
